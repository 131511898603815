  
  $blue: #0081d5;
  $light-blue: #1e90da;
  $dark-blue: #006cb0;
  $lighter-blue: #b4e1fa;
  $white: #fff;
  $lightest-grey: #fafbfc;
  $lighter-grey: #ebeff3;
  $light-grey: #c9d3df;
  $grey: #adb9c9;
  $dark-grey: #8393a7;
  $darker-grey: #53657d;
  $almost-black: #26353f;
  $black: #1c1c1c;
  $green: #03bd5b;
  $light-green: #daf5e7;
  $orange: #ff9947;
  $light-orange: #fff0e4;
  $red: #d1335b;
  $dark-red: #a13030;
  $light-red: #f8e1e7;
  $theme-primary: $blue;
  $theme-primary-light: $light-blue;
  $theme-primary-dark: $dark-blue;
  $theme-secondary: $blue;
  $theme-secondary-darken: $dark-blue;
  $theme-border: $grey;
  $theme-border-lighter: $light-grey;
  $theme-border-active: $light-blue;
  $theme-border-radius: 3px;
  $theme-background-white: $white;
  $theme-background-grey: $lighter-grey;
  $theme-background-color: $blue;
  $theme-background-dark: $black;
  $theme-box-shadow: $light-grey;
  $theme-label-text: $dark-grey;
  $theme-dark-text: $almost-black;
  $theme-success-border: $green;
  $theme-success-bg: $light-green;
  $theme-warning-border: $orange;
  $theme-warning-bg: $light-orange;
  $theme-error-border: $red;
  $theme-error-bg: $light-red;
  $theme-info-border: $blue;
  $theme-info-bg: $lighter-blue;
 
