/* Modifications du style pour faciliter la lecture */

.richtext {
  font-size: 1.125em;
}

.richtext h1 {
  font-size: 2.25em;
}

.richtext h2 {
  font-size: 1.75em;
}

.richtext h3 {
  font-size: 1.50em;
}

/* Augmenter la taille de la colonne visible pour les articles */

.article__container {
  max-width: 45em;
}

/* Utilisation du style de quote pour les blockquotes en attendant un patch */

blockquote {
  background-color: #ebeff3;
  border-left: 8px solid #c9d3df;
  padding: 4px 0 4px 25px;
}

/* Modification du style pour l'utilisation d'une grille pour les authors */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 2em;
}

.article__author {
  width: 100%;
}

.article__author.panel.inactive {
    color: #53657d;
    background-color: #c9d3df;
}

.article__author.panel.inactive img {
    filter: sepia(100%);
}

.article__author.panel.inactive img:hover {
    filter: sepia(0%);
}

.grid .panel + .panel {
    margin-left: 0;
}

.article__author a {
  text-decoration: none;
}

/* Style pour l'affichage du form newsletter */

.form__group + .button {
    margin-top: 2em;
}

.newsletter {
  width: 100%;
  text-align: center;
}
