.page .hero__container {
  min-height: unset;
}

.page .hero h1 {
  margin: 0;
}

.article__preview .article__author-name {
  margin-left: 0;
}

.article__preview img + .article__author-name {
  margin-left: 0.5em;
}